import React from 'react'
import Footer from '../../components/footer'
import Header from '../../components/header'
import SEO from '../../components/SEO'
import '../../styles/vpn.less'
import {
  ConsultationFormWrap,
  SaleFormWrap,
  // SaleForm,
} from '../../components/form-biz'
import { Map } from '../../pages-en/lp/baremetal'
const Button = ({
  type,
  onClick,
  // width = 'auto',
  children,
}: {
  children: React.ReactChild
  type?: string
  // width?: string
  onClick?: () => void
}) => (
  <button
    className={`vpnBtn ${type}`}
    onClick={() => {
      if (onClick) onClick()
    }}
    // style={{ width }}
  >
    {children}
  </button>
)
const onClickJumpBtn = (
  link = 'https://console.zenlayer.com/auth/signup/input'
) => {
  window.open(link)
}

const TopBanner = () => (
  <div className="topBanner">
    <div className="topBanner-bg">
      <div className="headerContainer">
        <Header theme="dark" />
      </div>
      <div className="content">
        <div className="left">
          <div className="subtitle">For VPN providers</div>
          <h1>More locations, more users</h1>
          <div className="description">
            Instantly scale servers across 40+ countries for better performance
            and privacy.
          </div>
          <div className="btnRow">
            <ConsultationFormWrap source="vpn">
              {({ setOpen }) => (
                <Button
                  onClick={() => {
                    setOpen(true)
                  }}
                >
                  Get a demo
                </Button>
              )}
            </ConsultationFormWrap>
            <Button type="primary" onClick={onClickJumpBtn}>
              Try for free
            </Button>
          </div>
        </div>
        {/* <div className="right">
          <SaleForm source="vpn" />
        </div> */}
      </div>
    </div>
  </div>
)
const B1 = () => {
  const products = [
    {
      name: 'Virtual machine',
      description:
        'Choose from a wide range of specs (1 – 32 vCPU, 2 – 128 GB RAM) and scale on demand.',
      url:
        'https://support.zenlayer.com/s/detail-page?article=Introduction-to-Virtual-Machine',
    },
    {
      name: 'Bare metal',
      description:
        'Get a dedicated physical server for high-performance computing needs.',
      url: 'http://www.zenlayer.com/products/bare-metal-cloud/',
    },
  ]
  return (
    <div className="b1">
      <div className="title">Give your users more choices and freedom</div>
      <div className="content">
        <div className="left">
          <Map id="zenlayer/clei4p1nm003s01qh1047ihk4" />
        </div>
        <div className="right">
          <div className="subtitle">
            Easily expand your global footprint with Zenlayer compute.
          </div>
          {products.map(({ name, description, url }: any) => (
            <div className="product">
              <div className="name">{name}</div>
              <div className="description">{description}</div>
              <Button type="primary" onClick={() => onClickJumpBtn(url)}>
                Learn more
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
const B2 = () => {
  return (
    <div className="b2">
      <div className="title">
        Deliver lightning-fast speeds wherever your users connect
      </div>
      <div className="content">
        <div className="left">
          <div className="subtitle">
            Zenlayer’s network offers significantly lower latency than major
            public clouds in emerging markets.
          </div>
          <div className="description">
            Our 50+ Tbps{' '}
            <a href="/products/ip-transit/" target="_blank">
              global network
            </a>{' '}
            is engineered with 45+ high-performance transit carriers, 5,500+
            direct peers, and dynamic routing.
          </div>
          <div className="description">
            Redundant network architecture and 24/7 monitoring ensures high
            reliability and uptime.
          </div>
        </div>
        <div className="right">
          <div className="subtitle">
            Sample latency comparison (in milliseconds):
          </div>
          <img src="/vpn/b2-pic.png" alt="" />
          <div className="description">
            Average latency to Saudi Arabia’s top five telecom carriers from
            nearest node
          </div>
        </div>
      </div>
    </div>
  )
}
const B3 = () => {
  return (
    <div className="b3">
      <div className="title">
        Add more security and privacy to give your users peace of mind
      </div>
      <div className="content">
        <div className="left">
          <img src="/vpn/b3-0.svg" alt="" />
          <div className="content">
            <div className="subtitle">
              Protect your traffic from prying eyes with Zenlayer’s
              <b> private global network.</b>
            </div>
            <div className="description">
              Access 400+ private lines worldwide.
            </div>
            <div className="description">
              Improve privacy, reduce jitter, and eliminate packet loss.
            </div>
          </div>
        </div>
        <div className="right">
          <img src="/vpn/b3-1.svg" alt="" />
          <div className="content">
            <div className="subtitle">
              Shield your network from bad actors with <b>DDoS mitigation.</b>
            </div>
            <div className="description">
              Filter DDoS attacks close to the source with Zenlayer’s 30+ global
              scrubbing centers.
            </div>
            <div className="description">
              Pay only for clean traffic. There are no penalties for spikes due
              to attack traffic.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const PrivadoProcurementManager = () => {
  return (
    <div className="privadoProcurementManager">
      <div className="content">
        <div className="left">
          <div>
            Zenlayer has been an incredible asset to our company. We are a VPN
            company with hosts around the world. Our relationship with Zenlayer
            was established because they can provide solid service in the most
            exotic locations. Their team is experienced, and their service is
            remarkable. With Zenlayer as a part of our team, we have expanded
            our footprint into locations we weren’t able to without them. We
            intend to continue our relationship with Zenlayer to expand our
            footprint.”
          </div>
          <div>Lisa Steele</div>
          <div>Privado Procurement Manager</div>
        </div>
        <div className="right">
          <img src="/vpn/privadoProcurementManager.png" alt="" />
        </div>
      </div>
    </div>
  )
}

const B4 = () => {
  const content = [
    'Prices start at $12/month for VMs',
    'Pay by month or enjoy discounts on longer terms',
    'Network billing available by data transfer or unmetered bandwidth',
  ]
  return (
    <div className="b4">
      <div className="title">Enjoy affordable and flexible pricing</div>
      {/* <div className="description">
        {
          'Receive free, live technical support with < 15 min response time 24/7.95% of tickets are resolved in less than 4 hours.'
        }
      </div> */}
      <div className="content">
        {content.map((c) => (
          <div>{c}</div>
        ))}
      </div>
    </div>
  )
}
const B5 = () => {
  const content = ['Reliable resolution ', 'Fast responses', 'Easy contact']
  return (
    <div className="b5">
      <div className="title">Relax with 24/7 technical support</div>
      <div className="description">
        {
          'Receive free, live technical support with < 15 min response time 24/7.95% of tickets are resolved in less than 4 hours.'
        }
      </div>
      <div className="content">
        {content.map((c, i) => (
          <div>
            <img src={`/vpn/b5-${i}.svg`} alt={c} />
            <div>{c}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
const B6 = () => {
  return (
    <div className="b6">
      <div className="left only-desktop">
        <iframe
          src="https://www.youtube.com/embed/S5GucCCq-Og"
          frameBorder="0"
          width="604px"
          height="341px"
          style={{
            borderRadius: '8px',
            position: 'absolute',
            top: '120px',
            right: '-132px',
          }}
        />
      </div>
      <div className="right">
        <div className="only-mobile">
          <iframe
            src="https://www.youtube.com/embed/S5GucCCq-Og"
            frameBorder="0"
            width="540px"
            height="341px"
            style={{
              borderRadius: '8px',
              // position: 'absolute',
              // top: '120px',
              // right: '-132px',
            }}
          />
        </div>
        <div className="title">Spin up a server in 2 minutes</div>
        <div className="btnRow">
          <ConsultationFormWrap source="vpn">
            {({ setOpen }) => (
              <Button
                type="primary"
                onClick={() => {
                  setOpen(true)
                }}
              >
                Get a demo
              </Button>
            )}
          </ConsultationFormWrap>
        </div>
        <SaleFormWrap source="vpn">
          {({ setOpen }) => (
            <Button
              onClick={() => {
                setOpen(true)
              }}
            >
              Contact sales
            </Button>
          )}
        </SaleFormWrap>
      </div>
    </div>
  )
}
export default function Home() {
  return (
    <div className="vpn">
      <SEO
        title="Zenlayer | Improve digital experiences. Instantly."
        description=""
        featuredImage="/featuredImage/zenlayer.jpg"
        noIndex={true}
      />
      <TopBanner />
      <B1 />
      <B2 />
      <B3 />
      <PrivadoProcurementManager />
      <B4 />
      <B5 />
      <B6 />

      <Footer />
    </div>
  )
}
